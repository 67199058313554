import api, { fetchExternal, fetchV2 } from "services/Api";

export const listReviews = async (params) => {
  const resp = await fetchV2("/store/reviews?" + new URLSearchParams(params));
  return resp;
};

export const createReviewUpload = async (id, name, file_type, file_size) => {
  return await fetchV2(`/store/reviews/${id}/photo/create/`, {
    method: "POST",
    body: JSON.stringify({ name, file_type, file_size }),
    contentType: "application/json",
  });
};

export const completeReviewUpload = async (id, key) => {
  return await fetchV2(`/store/reviews/${id}/photo/complete/`, {
    method: "POST",
    body: JSON.stringify({ key }),
    contentType: "application/json",
  });
};

export const uploadReview = async (url, file) => {
  return await fetchExternal(url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  });
};

export const postReview = async (payload) => {
  return await fetchV2(`/store/reviews`, {
    method: "POST",
    body: JSON.stringify(payload),
    contentType: "application/json",
  });
};

export const listAttributes = async () => {
  const resp = await api.get("/v1/attributes");

  return resp.data;
};

export const sendContactEmail = async ({
  name,
  email,
  subject,
  content,
  token,
}) => {
  const resp = await fetchV2("/store/contact", {
    method: "POST",
    body: JSON.stringify({
      name,
      email,
      subject,
      content,
      ["g-recaptcha-response"]: token,
    }),
  });

  return resp;
};
