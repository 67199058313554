import ReviewCard from "components/reviews/ReviewCard";
import Link from "next/link";
import { listReviews } from "services/AppService";

import RushTag from "assets/svg/rush-tag.svg";
import Meta from "components/common/Meta";
import Image from "next/image";
import Badge100Custom from "assets/svg/100-pct-custom.svg";
import BadgeUSAMade from "assets/svg/usa-made.svg";
import BadgeTopQuality from "assets/svg/top-quality.svg";

const HomePage = ({ reviews = [] }) => {
  return (
    <>
      <Meta
        title="Custom Air Fresheners | Lowest Prices | Custom Shapes"
        description="Design custom shape car air fresheners with faces, pets, or logos. Ideal for gifts, promotions, and bulk orders. Lowest prices and 24-hour production available"
        canonicalLink="https://www.makemyfreshener.com"
      />
      <div className="flex flex-col items-center w-full">
        <div className="relative w-full h-[472px] lg:h-[368px] flex items-center justify-center py-5">
          <Image
            src="https://flower-manufacturing.s3.amazonaws.com/frontend/makemyfresheners/img/feature.webp"
            layout="fill"
            objectFit="cover"
            objectPosition="top"
            alt="header freshener"
            priority
            sizes="30vw"
          />
          <header className="flex flex-col">
            <h1 className="uppercase drop-shadow-[0_4px_4px_rgba(0,40,70,1)] text-white text-5xl font-bold max-w-3xl text-center mb-12">
              Custom <br className="md:hidden" />
              Car Air Fresheners. Instantly Designed{""}
              <br className="md:hidden" /> By You.
            </h1>
            <div className="flex flex-col justify-center items-center space-y-5 w-fit mx-auto">
              <Link href="/shop">
                <a className="w-full drop-shadow-[0_4px_4px_rgba(0,40,70,1)] blue-button uppercase md:text-lg cursor-pointer">
                  Design now
                </a>
              </Link>
              <Link href="/samples" prefetch={false}>
                <a className="drop-shadow-[0_4px_4px_rgba(0,40,70,1)] white-button text-[#2463EB] uppercase md:text-lg cursor-pointer">
                  Try a sample pack
                </a>
              </Link>
            </div>
          </header>
        </div>

        <section className="max-w-[80rem] w-full flex justify-evenly items-center flex-col md:flex-row py-12 relative">
          <div className="lg:max-w-2xl lg:-ml-[300px] w-full px-8 lg:px-0">
            <h2 className="uppercase  font-semibold text-xl lg:text-3xl mb-5 text-center lg:text-left">
              Let Your imagination fly. Get fresh!
            </h2>
            <p className="tracking-wide leading-relaxed text-justify lg:text-left">
              Create unique custom air fresheners with a personal touch!
              Projects big and small, MakeMyFreshener is the leading
              manufacturer for the longest lasting, highest quality, custom car
              air fresheners around. Our custom car air fresheners are 100% USA
              made and use premium raw materials so you can shop with
              confidence, knowing you are receiving the the best product on the
              market at the lowest possible price. Just imagine your
              personalized design or corporate logo hanging for all to see, from
              rear view mirrors all around the world.
            </p>
          </div>
          <div className="flex flex-col lg:absolute lg:-top-32 lg:-right-0">
            <RushTag />
            <span className="text-[12px]  -mt-4 text-center">
              *Subject to some restrictions including quantity and cutoff time.
            </span>
          </div>
        </section>

        <section className="w-full bg-[#2463EB] mb-12 text-white">
          <div className="max-w-[70rem] grid lg:grid-cols-3 m-auto">
            <div className="py-6 px-4 sm:px-6 flex flex-col justify-between items-center h-full max-w-sm">
              <h3 className="text-xl font-semibold mb-4">
                PROUDLY MADE IN THE USA
              </h3>
              <BadgeUSAMade />
              <p className="text-center my-4">
                Our custom car air fresheners are 100% USA made by seasoned
                industry professionals. No cutting corners around here.
              </p>
              <Link href="/shop">
                <a className="w-full white-button uppercase text-[#2463EB] text-lg cursor-pointer">
                  See our shapes
                </a>
              </Link>
            </div>
            <div className="py-6 px-4 sm:px-6 flex flex-col justify-between items-center h-full max-w-sm">
              <h3 className="text-xl font-semibold mb-4">
                TOP QUALITY MATERIALS
              </h3>

              <BadgeTopQuality alt="TOP QUALITY MATERIALS" />

              <p className="text-center my-4">
                We use the top quality materials to give your custom air
                freshener a sturdy, durable feel with vibrant printing and a
                great fragrance.
              </p>
              <Link href="/samples" prefetch={false}>
                <a className="w-full white-button uppercase text-[#2463EB] text-lg cursor-pointer">
                  Sample requests
                </a>
              </Link>
            </div>
            <div className="py-6 px-4 sm:px-6 flex flex-col justify-between items-center h-full max-w-sm">
              <h3 className="text-xl font-semibold mb-4">
                CUSTOM DESIGNED BY YOU
              </h3>
              <Badge100Custom />
              <p className="text-center my-4">
                Sky's the limit with our easy custom car air freshener designer.
                Design the beautiful personalized air fresheners you have always
                dreamed of!
              </p>
              <Link href="/shop">
                <a className="w-full white-button uppercase text-[#2463EB] text-lg cursor-pointer">
                  Design now
                </a>
              </Link>
            </div>
          </div>
        </section>

        <section className="text-center mb-20 flex flex-col items-center max-w-5xl">
          <h3 className="font-semibold text-3xl">CUSTOMER REVIEWS</h3>
          <p className="tracking-wide leading-loose">
            We have sold millions of custom air fresheners to happy customers
            leading to 5 star reviews. Customers keep coming back due to our low
            price, fast turn around time, and easy ordering.
          </p>

          <div className="grid md:grid-cols-4 gap-4 w-full my-5">
            {reviews.slice(0, 4).map((review) => (
              <ReviewCard key={review.id} review={review} />
            ))}
          </div>

          <Link href="/reviews">
            <a className="blue-button cursor-pointer">VIEW REVIEWS</a>
          </Link>
        </section>

        <section className="mb-12 max-w-5xl px-5">
          <h2 className="font-semibold text-3xl">CUSTOM CAR AIR FRESHENERS</h2>
          <p className="tracking-wide text-justify leading-loose">
            Personalized air fresheners are a great way to keep your vehicle
            smelling great, but there’s no reason to stick to the tired
            store-bought designs. Make My Freshener allows you to personalize
            your custom car air freshener with a photo, text, and fragrance to
            create a conversation piece in your car. Your personalized air
            fresheners will be pre-strung and packaged in a pouch that will keep
            the fragrance fresh for years, if stored in a cool dry place.{""}
          </p>
          <p className="tracking-wide text-justify leading-loose mt-12">
            We customize our lives. You go out of your way to make sure
            everything from your clothes to the furniture in your house are a
            unique extension of your personality. Why wouldn’t your personalized
            car air freshener be the same instead of something common off the
            shelf? A personalized car air freshener is a conversation starter
            whether it’s something you’ve created just for yourself, or if it’s
            a wedding favor or a gift for your loved ones.
          </p>
          <h2 className="font-semibold text-3xl mt-16">
            MAKE YOUR OWN AIR FRESHENER
          </h2>
          <p className="tracking-wide text-justify leading-loose">
            Do you have engagement pictures and want to send out a memorable
            save the date announcement? A favorite picture of your dog that
            you’d like to see on your evening commute? Or do you just want a
            unique way to get the fast food smell out of your car? Whatever your
            goals, Make My Freshener has a ton of shapes and fragrances to make
            sure that your personal branded air freshener is personal and
            memorable.
          </p>

          <h2 className="font-semibold text-3xl mt-16">
            BRANDED AIR FRESHENERS
          </h2>
          <p className="tracking-wide text-justify leading-loose">
            We source all of our raw materials from the United States, and all
            of our freshener board is made up of 25% recycled material. We use
            sustainable materials because it makes us happy to deliver the
            highest quality product to our customers. We truly believe that if
            you’re going to make your own air freshener that your efforts should
            be rewarded with great looking high quality materials and printing!
            We manufacture our car air fresheners in Ohio: America’s heartland,
            and the hub of all custom air freshener activity in the United
            States. We quickly review your design to make sure it’ll look great,
            and then proceed to print, attach a string, add the fragrance, and
            package your car air freshener before we ship it directly to you.
            Because we control every step of the process, we’re able to answer
            any questions you may have!
          </p>
        </section>
      </div>
    </>
  );
};

export async function getStaticProps() {
  // const reviews = await listReviews({ page_size: 4 });

  return { props: { reviews: [] } };
}

export default HomePage;
